import React from 'react';
import feimg from '../../images/features/1.png'
import './style.css'



const WpoFeatures = () => {

    return(
        <div className="wpo-features-area">
            <div className="container">
                <div className="wpo-section-title text-center">
                    <span>Boardgame and </span>
                    <h2>RPG Industry Fulfillment</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="wpo-features-item-2">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="wpo-features-icon2">
                                        <i className="fi flaticon-plane"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>US Based Fulfillment</h3>
                                        <p>Your customers save money on shipping with our local US flex warehouses</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="feature-icon3">
                                        <i className="fi flaticon-truck"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>e-Commerce Fulfillment</h3>
                                        <p>We can pick and ship your orders for your online store with next-day white glove service</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="wpo-features-item">
                            <div className="wpo-feature-img">
                                <img src={feimg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="wpo-features-item">
                            <div className="col-lg-12 col-md-6 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="wpo-features-icon">
                                        <i className="fi flaticon-ship"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>Convention Flooring</h3>
                                        <p>We are local to most major shows giving you inventory flexibility</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="wpo-features-icon">
                                        <i className="fi flaticon-truck-1"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>Kickstarter Fulfillment</h3>
                                        <p>Ensure success with your crowdsourcing with our bulk order processing fulfillment service</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default WpoFeatures;
