import React from 'react';

import './style.css'

const ServiceArea = ( props ) => {

    return(
        <div className="wpo-service-s2">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>Boardgame and RPG Industry Fulfillment</span>
                        <h2>Reduce Your Risk, Increase Your Sales</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <h2>Convention Flooring</h2>
                                <p>With flex warehousing close to your events, we can hold inventory and resupply you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-ship"></i>
                                </div>
                                <h2>Kickstarter Fulfillment</h2>
                                <p>Reduce shipping expenses through our exclusive crowdsourcing fulfillment centers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-plane"></i>
                                </div>
                                <h2>Next Day Shipment</h2>
                                <p>Improve e-Commerce fulfillment with our white glove, next-day pick and ship services</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-construction"></i>
                                </div>
                                <h2>Increase Your Sales</h2>
                                <p>We market and advertise your products to our client community</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="flaticon-truck-2"></i>
                                </div>
                                <h2>Retailer Game Days</h2>
                                <p>Our GM League can rep your games in all of our CityRep locations</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="flaticon-trolley"></i>
                                </div>
                                <h2>Warehousing</h2>
                                <p>Rely on us for warehousing solutions with your own client dashboard</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea;
