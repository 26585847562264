import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'
import simg1 from '../../images/about-page/1.jpg'
import simg2 from '../../images/about-page/2.jpg'
import simg3 from '../../images/about-page/4.jpg'

const Mission = ( ) => {

    return(
        <div className="service-style-1">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>Boardgame and </span>
                        <h2>RPG Industry Fulfillment</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={simg1} alt=""/>
                                </div>
                                <div className="service-content">
                                    <h3>e-Commerce Fulfillment</h3>
                                    <p>We will pick and pack single orders for your online store</p>
                                    <Link to="/about">Sign Up Today</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                     <img src={simg2} alt=""/>
                                </div>
                                <div className="service-content2">
                                    <h3>Kickstarter Fulfillment</h3>
                                    <p>We can handle large crowdfunding fulfillment batches</p>
                                    <Link to="/about">Get Started Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={simg3} alt=""/>
                                </div>
                                <div className="service-content3">
                                    <h3>Distribution Fulfillment</h3>
                                    <p>We can sell your products into retail and the mass market trades</p>
                                    <Link to="/about">Increase Your Sales</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default Mission;
