import React from 'react';
import './style.css'
const ServiceArea3 = ( ) => {

    return(
        <div className="service-style-3">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>Boardgame and</span>
                        <h2>RPG Industry Fulfillment</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-truck"></i>
                            </div>
                            <div className="service-text">
                                <h3>Kickstarter Fulfillment</h3>
                                <p>We can handle large batch order processing for your crowdsourced projects</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-ship-1"></i>
                            </div>
                            <div className="service-text">
                                <h3>Local US Warehouses</h3>
                                <p>Save your customers money with our flex warehousing solutions in the US</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-plane"></i>
                            </div>
                            <div className="service-text">
                                <h3>Next-Day Pick and Ship</h3>
                                <p>Your e-Commerce orders can be processed next-day with our white glove service</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-construction-tool-vehicle-with-crane-lifting-materials"></i>
                            </div>
                            <div className="service-text">
                                <h3>Convention Flooring</h3>
                                <p>Enjoy close proximity to all the major conventions</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-box"></i>
                            </div>
                            <div className="service-text">
                                <h3>e-Commerce Fulfillment</h3>
                                <p>We can pick and ship your single online retail orders</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-trolley"></i>
                            </div>
                            <div className="service-text">
                                <h3>B2B and Trade Sales</h3>
                                <p>Our sales teams can get your products sold through distribution and mass market</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea3;
