import React from 'react';
import {Link} from 'react-router-dom'

import './style.css'


const CounterSection = (props) => {

    return(
        <div className={`wpo-counter-area ${props.subclass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-6 col-sm-12">
                        <div className="wpo-counter-content">
                            <h2>Here are some statistics that will give you confidence...</h2>
                            <p>We support some of the largest RPG and Boardgame industry titans including Modiphius, Mork Borg and more.  Through a simple strategy of Kickstarter Consulting, Convention Representation, Warehouse Fulfillment Operations, and Retail/Mass-Market Sales, we can increase your sales, reduce your risk and make your customers even happier than they are today.</p>
                            <div className="btns">
                                <div className="btn-style btn-style-3"><Link to="/">Learn More About Us...</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="wpo-counter-grids">
                            <div className="grid">
                                <div>
                                    <h2><span>4,012</span></h2>
                                </div>
                                <p>Delivered Packages</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span>10</span></h2>
                                </div>
                                <p>Major Conventions</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span>55</span></h2>
                                </div>
                                <p>Fulfilled Kickstarters</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span>6,000</span></h2>
                                </div>
                                <p>Game Hours Played</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default CounterSection;
