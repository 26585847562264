import React from 'react';
import {Link} from 'react-router-dom'
import sv1 from '../../images/service/1.jpg'
import sv2 from '../../images/service/2.jpg'
import sv3 from '../../images/service/3.jpg'
import sv4 from '../../images/service/4.jpg'
import sv5 from '../../images/service/5.jpg'
import sv6 from '../../images/service/6.jpg'


const ServiceArea2 = ( props ) => {

    return(
        <div className="wpo-service-area">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>Boardgame and</span>
                        <h2>RPG Industry Fulfillment</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv1} alt=""/>
                                </div>
                                <div className="service-content">
                                    <h3>Kickstarter Fulfillment</h3>
                                    <p>We can handle your large scale batch orders from Kickstarter</p>
                                    <Link to="/servicesingle">See More...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv2} alt=""/>
                                </div>
                                <div className="service-content2">
                                    <h3>Reduce Shipping Costs</h3>
                                    <p>Our flex warehousing in multiple US locations can give your customers lower shipping costs</p>
                                    <Link to="/servicesingle">See More...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv3} alt=""/>
                                </div>
                                <div className="service-content3">
                                    <h3>Rapid Turnaround</h3>
                                    <p>Take advantage of our white glove, next-day pick and ship service for your e-Commerce orders</p>
                                    <Link to="/servicesingle">See More...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv4} alt=""/>
                                </div>
                                <div className="service-content4">
                                    <h3>Convention Flooring</h3>
                                    <p>Relax as you exhibit at your favorite shows with our convention flooring inventory refresh program</p>
                                    <Link to="/servicesingle">See More...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv5} alt=""/>
                                </div>
                                <div className="service-content5">
                                    <h3>e-Commerce Fulfillment</h3>
                                    <p>We will pick and ship your products next-day with white glove service</p>
                                    <Link to="/servicesingle">See More...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv6} alt=""/>
                                </div>
                                <div className="service-content6">
                                    <h3>Warehousing</h3>
                                    <p>Rely on us for warehouse and fulfillment services with multiple locations throughout the US</p>
                                    <Link to="/servicesingle">See More...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea2;
