import React from 'react';
import {Link} from 'react-router-dom'

import './style.css'

const Features3 = () => {

    return(
        <div className="wpo-section-style-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-9 col-sm-12 col-d c-pd">
                        <div className="section-wrap">
                            <div className="wpo-section-item-2">
                                <div className="wpo-section-icon">
                                    <i className="fi flaticon-ship"></i>
                                </div>
                                <div className="wpo-section-content">
                                    <p><Link to="/ocean">e-Commerce Fulfillment</Link></p>
                                    <span>We will pick and pack single orders for your online store</span>
                                </div>
                            </div>
                            <div className="wpo-section-item-2">
                                <div className="wpo-section-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <div className="wpo-section-content">
                                    <p><Link to="/road">Kickstarter Fulfillment</Link></p>
                                    <span>We can handle large crowdfunding fulfillment batches</span>
                                </div>
                            </div>
                            <div className="wpo-section-item-2">
                                <div className="wpo-section-icon">
                                    <i className="fi flaticon-plane"></i>
                                </div>
                                <div className="wpo-section-content">
                                    <p><Link to="/freight">Distribution Fulfillment</Link></p>
                                    <span>We can sell your products into retail and the mass market trades</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="tr-wrap">
                            <div className="t-text">
                                <h2>Boardgame and RPG Industry Fulfillment</h2>
                                <p>Distribution and fulfillment for Gamers, by Gamers.  Because we consult with so many companies on Kickstarters and e-Commerce, we have the warehouse infrastructure necessary to handle your fulfillment.</p>
                            </div>
                            <div className="tr-s">
                                <span>e-Commerce single-order pick and ship in the US, Canada and worldwide</span>
                                <span>Kickstarter batch fulfillment with white glove service</span>
                                <span>Convention flooring convenient to Midwest conventions</span>
                                <span>Reliable, on-time delivery to reinforce your brand</span>
                                <span>Additional sales opportunities through our client network</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default Features3;
