import React from 'react';

import {Link} from 'react-router-dom'
import './style.css'

const PricingSection = (props) => {

    return(
        <div className="wpo-pricing-area">
            <div className="container">
                <div className="wpo-section-title text-center">
                    <span>Our programs are simple!</span>
                    <h2>Program Options</h2>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-4 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Simple</h3>
                                        <span>Program</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                        <div className="wpo-price-sub-header">
                                            <h4>Small Publishers</h4>
                                        </div>
                                        <ul>
                                            <li>Shared Booth Presence</li>
                                            <li>Interlaced Cross-Sells</li>
                                            <li>Kickstarter Fulfillment</li>
                                            <li>e-Commerce Fulfillment</li>
                                            <li>GM League Organized Play</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><Link to="/">Apply for 2024 Today</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Standard</h3>
                                        <span>Program</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                        <div className="wpo-price-sub-header">
                                            <h4>Mid-Tier Publishers</h4>
                                        </div>
                                        <ul>
                                            <li>US Fulfillment</li>
                                            <li>Branded Booth Presence</li>
                                            <li>Kickstarter Fulfillment</li>
                                            <li>e-Commerce Fulfillment</li>
                                            <li>GM League Organized Play</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><Link to="/">Apply for 2024 Now</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Enterprise</h3>
                                        <span>Program</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                        <div className="wpo-price-sub-header">
                                            <h4>Large Publishers</h4>
                                        </div>
                                        <ul>
                                            <li>Multi Convention Management</li>
                                            <li>Full Retail and Mass-Market Sales</li>
                                            <li>Kickstarter Fulfillment</li>
                                            <li>e-Commerce Fulfillment</li>
                                            <li>GM League Organized Play</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><Link to="/">Start Now</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default PricingSection;
