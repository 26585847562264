import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportAir = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: (307) 228-5315</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>sales@gamecodex.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>30 North Gould Street, Suite 22123, Sheridan, WY 82801</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Fulfillment Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/">Kickstarter Fulfillment</Link></li>
                                            <li><Link to="/">Convention Flooring</Link></li>
                                            <li><Link to="/">e-Commerce Pick and Ship</Link></li>
                                            <li><Link to="/">Flexible US Warehouses</Link></li>
                                            <li><Link to="/">Additional Sales Representation</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>US-Based Warehouse Fulfillment</h3>
                            <p>Open your majority of customers to our white glove US based fulfillment services</p>
                            <img src={props.simg} alt=""/>
                            <p>With flex warehousing throughout the major metro areas, we are closest to your events and to your customers</p>
                            <div className="Freight-s">
                                <div className="Freight-img">
                                     <img src={props.simg2} alt=""/>
                                </div>
                                <div className="f-s">
                                    <span>e-Commerce single-order pick and ship in the US, Canada and worldwide</span>
                                    <span>Kickstarter batch fulfillment with white glove service</span>
                                    <span>Convention flooring convenient to Midwest conventions</span>
                                    <span>Reliable, on-time delivery to reinforce your brand</span>
                                    <span>Additional sales opportunities through our client network</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default TransportAir;
