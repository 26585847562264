import React from 'react';
import abimg1 from '../../images/about/about.svg'
import abimg2 from '../../images/about/1.png'

import './style.css'

const AboutSection = () => {

    return(
        <div className="wpo-about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="wpo-about-img">
                              <img src={abimg1} alt=""/>               
                        </div>
                    </div>
                    <div className="col-lg-6 sec-p">
                        <div className="wpo-about-content">
                            <div className="wpo-about-icon">
                                <i className="fi flaticon-travel"></i>
                            </div>
                            <h2>Who is GameCodex?</h2>
                            <p>We consult with some of the largest companies to manage their complex logistics issues with the following services:</p>
                            <span>Kickstarter Consulting</span>
                            <span>Convention Presence and Flooring</span>
                            <span>e-Commerce Fulfillment and Inventory</span>
                        </div>
                        <div className="signature-section">
                            <div className="si-text">
                                <p>Contact: Jonathan Albin</p>
                                <span>Director of Sales</span>
                            </div>
                            <img src={abimg2} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default AboutSection;
